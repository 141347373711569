import { useEffect, useLayoutEffect } from "react";

import { Box, Container, LinearProgress } from "@mui/material";

import { useNav } from "../../context/NavProvider";
import { useAccount } from "../../context/AccountProvider";
import { useSfs } from "../../context/SfsProvider";
import { useArrangement } from "../../context/ArrangementProvider";

import ChangePaymentMethodStepper from "../arrangement/ChangePaymentMethodStepper";
import ScriptLoader from "../account/ScriptLoader";

import { useNavigate } from "react-router-dom";

function PaymentMethodPage() {

  const { setShowFooter } = useNav();
  setShowFooter(false);

  const { useGetAccount } = useAccount();
  const { useGetSfs, loadSfsData, sfsLoaded } = useSfs();
  const {
    useGetArrangement,
    loadPlanData,
    planLoaded,
    setPlan,
    lastSavedPlan,
    savePlanData,
  } = useArrangement();

  const { account, isAccountLoading } = useGetAccount();
  const { sfs, isSfsLoading } = useGetSfs();
  const { arrangement, isArrangementLoading } = useGetArrangement();

  const navigate = useNavigate();

  useLayoutEffect(() => {
    // Redirect to home and do not allow plan changes in payment window
    if (lastSavedPlan && lastSavedPlan?.inPaymentWindow === true) {
      navigate("/");
    }
  }, [lastSavedPlan]);

  useEffect(() => {
    // Redirect to home and do not allow plan changes in payment window
    if (lastSavedPlan && lastSavedPlan?.inPaymentWindow === true) {
      navigate("/");
    }
  }, [lastSavedPlan]);

  if (isAccountLoading || isSfsLoading || isArrangementLoading) {
    return <LinearProgress />;
  }

  if (!planLoaded) {
    loadPlanData(arrangement);
  }

  if (!sfsLoaded) {
    loadSfsData(sfs);
  }

  return (
    account &&
    planLoaded &&
    sfsLoaded && (
      <>
        <ScriptLoader /> {/* Load scripts based on consent */}
        <Container sx={{ mt: 4, mb: 7, paddingBottom: "350px" }}>
          <Box>
            <ChangePaymentMethodStepper
              account={account}
              plan={lastSavedPlan}
              setPlan={setPlan}
              savePlan={savePlanData}
            />
          </Box>
        </Container>
        {setShowFooter(true)}
      </>
    )
  );
}

export default PaymentMethodPage;
