import React, { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MaintenancePage from "./components/pages/MaintenancePage";
import Layout from "./components/nav/Layout";
import ContactUsPage from "./components/pages/ContactUs";
import ArrangementPage from "./components/pages/Arrangement";
import PaymentMethodPage from "./components/pages/PaymentMethod";
import ErrorPage from "./components/pages/Error";
import NotFoundPage from "./components/pages/NotFound";
import IdvAuthPage from "./components/pages/IdvAuth";
import AccountPage from "./components/pages/Account";
import PaymentPage from "./components/pages/Payment";
import PaymentConfirmation from "./components/payment/PaymentConfirmation";
import SfsPage from "./components/pages/Sfs";
import ScrollToTop from "./components/nav/ScrollToTop";
import environment from "./env";

import { useClient } from "./context/ClientProvider";
import { useNav } from "./context/NavProvider";
import { AuthGuard } from "./components/auth/AuthGuard";
import CookieProvider, { useCookie } from "./context/CookieProvider";
import CookieConsentDialog from "./components/account/CookieConsentDialog";
import ScriptLoader from "./components/account/ScriptLoader";

function App() {
  const { useGetConfig } = useClient();
  const { clientConfig, isLoading } = useGetConfig(environment.CLIENT_NAME);
  const { setShowFooter } = useNav();
  const { getCookie, setCookie } = useCookie();
  const [open, setOpen] = useState(false);
  const [consentGiven, setConsentGiven] = useState(false);

  useEffect(() => {
    const consent = getCookie("google_analytics");
    if (consent === "accepted") {
      setConsentGiven(true);
    } else if (!consent) {
      setOpen(true);
    }
  }, [getCookie]);

  useEffect(() => {
    setShowFooter(true);
  }, [setShowFooter]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleAccept = () => {
    setCookie("google_analytics", "accepted", 365, "/");
    setConsentGiven(true);
    handleClose();
  };

  const handleDecline = () => {
    setCookie("google_analytics", "declined", 365, "/");
    handleClose();
  };

  const isMaintenanceMode = environment.MAINTENANCE_MODE !== "false";

  if (isMaintenanceMode) {
    console.warn("Maintenance Mode:", isMaintenanceMode);
    return <MaintenancePage />;
  }

  if (!isLoading && clientConfig) {
    const router = createBrowserRouter([
      {
        path: "/",
        element: <Layout />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: "",
            element: (
              <AuthGuard>
                <ScrollToTop />
                <AccountPage />
              </AuthGuard>
            ),
          },
          { path: "auth", element: <IdvAuthPage /> },
          { path: "contact-us", element: <ContactUsPage /> },
          {
            path: "payment",
            element: (
              <AuthGuard>
                <ScrollToTop />
                <PaymentPage />
              </AuthGuard>
            ),
          },
          {
            path: "sfs",
            element: (
              <AuthGuard>
                <ScrollToTop />
                <SfsPage />
              </AuthGuard>
            ),
          },
          { path: "*", element: <NotFoundPage /> },
        ],
      },
      {
        path: "/repayment",
        element: <Layout />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: "",
            element: (
              <AuthGuard>
                <ScrollToTop />
                <ArrangementPage />
              </AuthGuard>
            ),
          },
          {
            path: "change-payment",
            element: (
              <AuthGuard>
                <ScrollToTop />
                <PaymentMethodPage />
              </AuthGuard>
            ),
          },
          { path: "*", element: <NotFoundPage /> },
        ],
      },
      {
        path: "/payment/:transactionRef",
        element: (
          <AuthGuard>
            <ScrollToTop />
            <PaymentConfirmation />
          </AuthGuard>
        ),
        loader: ({ params }) => params.transactionRef,
      },
    ]);

    return (
      <CookieProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
          <ThemeProvider theme={createTheme(clientConfig?.theme)}>
            <RouterProvider router={router} />
            <CookieConsentDialog
              open={open}
              onClose={handleClose}
              onAccept={handleAccept}
              onDecline={handleDecline}
            />
            {consentGiven && <ScriptLoader />}
          </ThemeProvider>
        </LocalizationProvider>
      </CookieProvider>
    );
  }

  return null;
}

export default App;
