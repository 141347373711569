import { useState, useEffect } from "react";
import {
  Box,
  Stack,
  Typography,
  TextField,
  Card,
  CardContent,
  Select,
  MenuItem,
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useArrangement } from "../../context/ArrangementProvider";
import { usePayment } from "../../context/PaymentProvider";
import SfsHelp from "../sfs/SfsHelp";
import ScrollToTop from "../nav/ScrollToTop";
function PaymentQuestions({
  balance,
  handleNext,
  paymentAmount,
  updatePaymentAmount,
  setHideRightColumn,
  showSfsHelp,
  setShowSfsHelp,
}) {
  const [showAmountInput, setShowAmountInput] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const { useGetArrangement, hasArrangement } = useArrangement();
  const { arrangement } = useGetArrangement();

  const instalmentAmount = arrangement?.instalmentAmount || 0;
  const {
    paymentAmountOption,
    setPaymentAmountOption,
    priorityBillsOptions,
    priorityBillsAnswer,
    setPriorityBillsAnswer,
    sourceOfFundsOptions,
    sourceOfFundsAnswer,
    setSourceOfFundsAnswer,
    otherCreditorsOptions,
    otherCreditorsAnswer,
    setOtherCreditorsAnswer,
    furtherBorrowing,
    noCreditorNoPlan,
  } = usePayment();

  const navigate = useNavigate();

  function handleSfsHelp() {
    if (
      priorityBillsAnswer === noCreditorNoPlan ||
      sourceOfFundsAnswer === furtherBorrowing ||
      otherCreditorsAnswer === noCreditorNoPlan) {
      setShowSfsHelp(priorityBillsAnswer === noCreditorNoPlan || otherCreditorsAnswer === noCreditorNoPlan)    
      setHideRightColumn(true);
    } else {
      setHideRightColumn(false);
      handleNext();
    }
  }

  const changePaymentOption = (event) => {
    const option = event.target.value;
    if (option === "Other Amount") {
      setShowAmountInput(true);
      setPaymentAmountOption(option);
      updatePaymentAmount(0);
    } else {
      setShowAmountInput(false);
      setPaymentAmountOption(option);
      updatePaymentAmount(instalmentAmount);
    }
  }

  const handleAmountChange = (event) => {
    const amount = parseFloat(event.target.value);
    if (!isNaN(amount) && amount > 0) {
      updatePaymentAmount(amount);
    } else {
      updatePaymentAmount(0);
    }
  };

  const isContinueButtonDisabled = () => {
    return (
      paymentAmount <= 0 ||
      !priorityBillsAnswer ||
      !sourceOfFundsAnswer ||
      !otherCreditorsAnswer ||
      (showSfsHelp && !checkboxChecked)
    );
  };

  return (
    showSfsHelp ?    
      <>
        <ScrollToTop />
        <SfsHelp />
        <Card sx={{pt: "8px", display: "flex", height: "65px"}}>
          <CardContent sx={{ pt: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxChecked}
                  onChange={(event) => setCheckboxChecked(event.target.checked)}
                />
              }
              label={
                <Typography fontSize={14} textAlign='left'>
                  I confirm that I have considered my need for additional
                  support and I am happy to make a payment towards my account.
                </Typography>
              }
              sx={{ textAlign: "left" }}
            />
          </CardContent>
        </Card>
        <Box sx={{ pt: 4, textAlign: { xs: "center", md: "left" } }}>
          <Button
            onClick={() => navigate("/")}
            variant='outlined'
            sx={{
              textTransform: "none",
              borderRadius: 0,
              marginRight: "15px",
              width: { xs: "5em", lg: "10em" },
              backgroundColor: "transparent",
              color: "secondary.main",
              borderColor: "secondary.main",
              "&:hover": {
                backgroundColor: "#fff",
                color: "secondary.main",
              },
            }}
          >
            Back
          </Button>
          <Button
            id='continue-btn'
            disabled={isContinueButtonDisabled()}
            onClick={handleNext}
            variant='contained'
            sx={{
              textTransform: "none",
              borderRadius: 0,
              width: { xs: "5em", lg: "10em" },
              backgroundColor: "primary.dark",
              color: "primary.contrastText",
              "&:hover": {
                backgroundColor: "primary.light",
                color: "primary.dark",},
              }}
            >
              Continue
            </Button>
        </Box>
      </>
      :
    <Card
      sx={{
        borderRadius: { xs: 0, md: 0 },
        pb: 3,
        width: "100%",
      }}
    >
      <Box>
        <CardContent
          sx={{
            textAlign: "left",
            borderRadius: "0px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* Form content here */}
          <Stack
            spacing={2}
            direction={{
              xs: "column",
              md: "row",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Typography
                component='label'
                htmlFor='balance'
                fontSize={14}
                paddingY={0.5}
              >
                Balance
              </Typography>
              <Box
                id='balance'
                sx={{
                  backgroundColor: "#E2E2E2",
                  borderRadius: 0,
                  border: "1px solid #BBBBBB",
                  p: 1,
                  fontWeight: "bold",
                  fontSize: 16,
                }}
              >{`£${balance.toFixed(2)}`}</Box>
            </Box>

            <Box sx={{ width: "100%" }}>
              <Box paddingBottom={1}>
                <Typography
                  component='label'
                  htmlFor='select-amount'
                  fontSize={14}
                  paddingY={0.5}
                >
                  Select payment amount
                </Typography>
                <Select
                  id='amount-select'
                  fullWidth
                  value={paymentAmountOption}
                  displayEmpty
                  sx={{ borderRadius: "0px" }}
                  onChange={changePaymentOption}
                  size='small'
                  renderValue={(selected) => {
                    if (selected === "") {
                      return (
                        <Typography fontWeight='bold'>
                          Choose an option
                        </Typography>
                      );
                    } else {
                      return selected;
                    }
                  }}
                >
                  {hasArrangement && (
                    <MenuItem
                      id='agreed-repayment'
                      value={`Agreed repayment`}
                    >{`Agreed repayment - £${instalmentAmount.toFixed(
                      2
                    )}`}</MenuItem>
                  )}
                  <MenuItem id='other-amount' value='Other Amount'>
                    Other amount
                  </MenuItem>
                </Select>
              </Box>
              {/* End of select payment amount */}

              
              {(showAmountInput || !hasArrangement) && (
                <Box>
                  <Typography
                    component='label'
                    htmlFor='amount'
                    fontSize={14}
                    paddingY={0.5}
                  >
                    Amount
                  </Typography>

                  <TextField
                    id='amount'
                    type='number'
                    InputProps={{ sx: { borderRadius: 0 } }}
                    fullWidth
                    size={"small"}
                    value={paymentAmount > 0 ? paymentAmount : ""}
                    onChange={handleAmountChange}
                  />
                </Box>
              )}
              {/* End of enter payment amount */}
            </Box>
          </Stack>

          {/* Priority bills */}
          <Box sx={{ pt: 3, pb: 1, width: "100%" }}>
            <Typography fontSize={16} fontWeight='bold'>
              Are your priority bills up to date?
            </Typography>
            <Typography fontSize={14} paddingY={0.5}>
              e.g. your mortgage, your rent, and/or your energy bills such as
              your Gas, Electricity & Council Tax?
            </Typography>
          </Box>

          <Box sx={{ width: { xs: "100%", md: "49%" } }}>
            <Select
              id='priority-bills-select'
              fullWidth
              displayEmpty
              value={priorityBillsAnswer || ""}
              onChange={(event) => setPriorityBillsAnswer(event.target.value)}
              size='small'
              renderValue={(selected) => {
                if (selected === "") {
                  return (
                    <Typography fontWeight='bold'>Please select</Typography>
                  );
                } else {
                  return selected;
                }
              }}
            >
              {priorityBillsOptions.map((item) => (
                <MenuItem key={item.id} id={item.id} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
          {/* End of priority bills */}

          {/* Source of funds */}
          <Box sx={{ pt: 4, pb: 1, width: "100%" }}>
            <Typography fontSize={16} fontWeight='bold'>
              How will you fund this payment?
            </Typography>
            <Typography fontSize={14} paddingY={0.5}>
              Where will the payment come from?
            </Typography>
          </Box>

          <Box sx={{ width: { xs: "100%", md: "49%" } }}>
            <Select
              id='source-of-funds-select'
              fullWidth
              displayEmpty
              value={sourceOfFundsAnswer || ""}
              onChange={(event) => setSourceOfFundsAnswer(event.target.value)}
              size='small'
              renderValue={(selected) => {
                if (selected === "") {
                  return (
                    <Typography fontWeight='bold'>Please select</Typography>
                  );
                } else {
                  return selected;
                }
              }}
            >
              {sourceOfFundsOptions.map((item) => (
                <MenuItem key={item.id} id={item.id} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
          {/* End of source of funds */}

          {/* Other creditors */}
          <Box sx={{ pt: 4, pb: 1, width: "100%" }}>
            <Typography fontSize={16} fontWeight='bold'>
              Are you up to date with any other creditors?
            </Typography>
            <Typography fontSize={14} paddingY={0.5}>
              Are there other creditors e.g. Are you repaying another loans at the moment or repaying other forms of lending?
            </Typography>
          </Box>

          <Box sx={{ width: { xs: "100%", md: "49%" } }}>
            <Select
              id='other-creditors-select'
              fullWidth
              displayEmpty
              value={otherCreditorsAnswer || ""}
              onChange={(event) => setOtherCreditorsAnswer(event.target.value)}
              size='small'
              renderValue={(selected) => {
                if (selected === "") {
                  return (
                    <Typography fontWeight='bold'>Please select</Typography>
                  );
                } else {
                  return selected;
                }
              }}
            >
              {otherCreditorsOptions.map((item) => (
                <MenuItem key={item.id} id={item.id} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
          {/* End of other creditors */}

          {/* Call To Action */}
          <Box sx={{ pt: 4, textAlign: { xs: "center", md: "left" } }}>
            <Button
              onClick={() => navigate("/")}
              variant='outlined'
              sx={{
                textTransform: "none",
                borderRadius: 0,
                marginRight: "15px",
                width: { xs: "5em", lg: "10em" },
                backgroundColor: "transparent",
                color: "secondary.main",
                borderColor: "secondary.main",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "secondary.main",
                },
              }}
            >
              Back
            </Button>
            <Button
              id='continue-btn'
              disabled={isContinueButtonDisabled()}
              onClick={handleSfsHelp}
              variant='contained'
              sx={{
                textTransform: "none",
                borderRadius: 0,
                width: { xs: "5em", lg: "10em" },
                backgroundColor: "primary.dark",
                color: "primary.contrastText",
                "&:hover": {
                  backgroundColor: "primary.light",
                  color: "primary.dark",
                  }
                }}
            >
              Continue
            </Button>
          </Box>
        </CardContent>
      </Box>
    </Card>
  )}
  
export default PaymentQuestions;
