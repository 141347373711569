import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Container,
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Button,
} from "@mui/material";

import { useUtils } from "../../context/UtilsProvider";
import { useArrangement } from "../../context/ArrangementProvider";


function ChangePaymentMethodSelect({
  account,
  consent,
  setConsent,
  setErrorMessage,
  setCurrentSortCode,
  setCurrentAccountNumber,
  selectedMethod,
  setSelectedMethod,
  savePaymentMethod
}) {
  const { capitaliseWords } = useUtils();
  const { plan, paymentMethods } = useArrangement();

  const [ paymentMethodLabels, setPaymentMethodLabels ] = useState([
    {
      id: 11,
      label: "Direct Debit",
    },
    {
      id: 5,
      label: "Card Payment",
    }
  ]);

  useEffect(() => {
    setPaymentMethodLabels(paymentMethodLabels.map((item) => selectedMethod === item.id ? {
      ...item, checked: true } : item));
  }, []);

  const handleChange = (event) => {
    setSelectedMethod(parseInt(event.target.value));
      // Reset consent, errors & inputs on new selection
    setConsent(false);
    setErrorMessage(null);
    setCurrentSortCode(null);
    setCurrentAccountNumber(null);
  };

  return plan && (
    <>
      <Box
        sx={{
          borderRadius: 1,
          border: "1px solid lightgrey",
          pb: 3,
        }}
      >
        {/* Header section */}
        <Box
          sx={{
            pb: 1,
            borderBottom: "1px solid lightgrey",
            borderRadius: 1,
            backgroundColor: "white",
          }}
        >
          <Container sx={{ width: "100%" }}>
            <Typography fontSize={18} fontWeight={"bold"} paddingY={1}>
              {account?.creditorName}
            </Typography>
            <Typography fontSize={13}>Reference number:</Typography>
            <Typography id='reference-num' fontSize={16} fontWeight={"bold"}>
              {account?.accountReference}
            </Typography>
          </Container>
        </Box>
        {/* End of header section */}

        <Box
          sx={{
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            mx: 2,
          }}
        >
          <Typography sx={{ fontSize: 13, ml: 1, mt: 2, fontWeight: "bold" }}>
            Select payment method
          </Typography>
          <Typography sx={{ fontSize: 10, ml: 1, mb: 1 }}>
            Current payment method: {capitaliseWords(plan?.paymentMethod ? Object.keys(
              paymentMethods).find(key => paymentMethods[key] === plan?.paymentMethod) : "")}
          </Typography>

          <FormControl>
            <FormLabel id="payment-methods-radio-buttons-group"></FormLabel>
            <RadioGroup
              aria-labelledby="payment-methods-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={selectedMethod}
              onChange={handleChange}
            >
              {paymentMethodLabels.map((item, idx) => (
                <Card key={idx} sx={{
                  mt: 1,
                  mx: 1,
                  px: 2,
                  py: 1,
                  "&:hover": {
                    backgroundColor: "primary.dark",
                    color: "#FFF"
                  }
                }}>
                  <Box>
                    <FormControlLabel value={item.id} control={<Radio />} label={item.label} />
                  </Box>
                </Card>
              ))}
            </RadioGroup>
          </FormControl>

          {/* Call To Action */}
          <Box sx={{ textAlign: "center", pt: 4, pb: 1 }}>
            <Button
              variant='contained'
              disabled={!consent}
              sx={{
                textTransform: "none",
                borderRadius: 0,
                width: { xs: "15em", lg: "18em" },
                backgroundColor: "primary.dark",
                color: "primary.contrastText",
                "&:hover": {
                  backgroundColor: "primary.light",
                  color: "primary.dark",
                },
              }}
              onClick={savePaymentMethod}
            >
              Continue
            </Button>
          </Box>

        </Box>
      </Box>
    </>
  )
}

export default ChangePaymentMethodSelect;