import { useState, useEffect } from "react";
import { Box, Typography, Stack  } from "@mui/material";

import ChangePaymentMethodSelect from "./ChangePaymentMethodSelect";
import ArrangementCardForm from "./ArrangementCardForm";
import ArrangementDirectDebitForm from "./ArrangementDirectDebitForm";

import { useArrangement } from "../../context/ArrangementProvider";

function ChangePaymentMethod({ account, plan, setPlan, savePlan, handleComplete }) {

  const { paymentMethods, getPlanEndDate, calculateStartDate, getFinalPaymentAmount } = useArrangement();

  const [ consent, setConsent ] = useState(false);
  const [ selectedMethod, setSelectedMethod ] = useState(null);
  const [ currentSortCode, setCurrentSortCode ] = useState(null);
  const [ currentAccountNumber, setCurrentAccountNumber ] = useState(null);

  const [ errorMessage, setErrorMessage ] = useState(null);

  const SORT_CODE_LENGTH = 6;
  const ACCOUNT_NUMBER_LENGTH = 8;

  useEffect(() => {
    // Set default selected method to existing method if supported, otherwise direct debit
    let paymentMethod = plan?.paymentMethod ? parseInt(plan?.paymentMethod) : null;
    paymentMethod = (paymentMethod && paymentMethod !== 5) ? 11 : 5;
    setSelectedMethod(paymentMethod);
    setConsent(false); // Reset consent on page load
  }, []);

  const savePaymentMethod = () => {
    // Triggered when Continue is pressed in step 1
    // Validate sort code and account number
    if (selectedMethod === paymentMethods["UK DIRECT DEBIT"]) {
      if (currentSortCode === null || currentSortCode.length < SORT_CODE_LENGTH) {
        setErrorMessage(`Sort code must contain ${SORT_CODE_LENGTH} numbers.`);
        return;
      } else if (currentAccountNumber === null || currentAccountNumber.length < ACCOUNT_NUMBER_LENGTH) {
        setErrorMessage(`Account number must contain ${ACCOUNT_NUMBER_LENGTH} numbers.`);
        return;
      }
    }

    const currentBalance = account?.currentBalance;
    const instalmentAmount = plan?.instalmentAmount;

    const newStartDate = calculateStartDate(
      plan?.paymentFrequency, plan?.monthlyDay, plan?.weeklyDay);

    // How many instalments would clear the balance using the existing payment amount?
    const totalInstalments = Number(Math.ceil(currentBalance / instalmentAmount));
    const MAX_INSTALMENTS = 250;

    let planNumInstalments = null;
    let finalPaymentAmount = null;
    let planTotalAmount = null;

    if (totalInstalments <= MAX_INSTALMENTS) {
      // This amount can clear the balance without exceeding max allowed instalments
      planNumInstalments = totalInstalments;
      finalPaymentAmount = getFinalPaymentAmount(currentBalance, instalmentAmount, planNumInstalments);
      planTotalAmount = currentBalance;
    } else {
      // This amount cannot clear the balance so cap at max instalments
      planNumInstalments = MAX_INSTALMENTS;
      finalPaymentAmount = instalmentAmount;
      planTotalAmount = instalmentAmount * planNumInstalments;
    }
    
    const newEndDate = getPlanEndDate(
      newStartDate, planNumInstalments, plan?.paymentFrequency);

    const newPlan = {
      ...plan,
      dateCreated: newStartDate,
      instalmentAmount: instalmentAmount,
      nextPaymentAmount: instalmentAmount,
      nextPaymentDate: newStartDate,
      inPaymentWindow: false,
      numberOfInstalments: planNumInstalments,
      paymentsLeft: planNumInstalments,
      paidToDate: 0,
      totalPayable: planTotalAmount,
      totalRemaining: planTotalAmount,
      paymentMethod: selectedMethod,
      sortCode: currentSortCode,
      accountNumber: currentAccountNumber,
      endDate: newEndDate,
      finalPaymentAmount: finalPaymentAmount,
    }

    setPlan(newPlan);
    savePlan(newPlan);

    handleComplete();
  };

  return (
    <>
      <Typography fontWeight={"bold"}>
        Change your payment method
      </Typography>
      <Stack
        sx={{
          mt: 2,
        }}
        spacing={2}
        direction={{
          xs: "column",
          md: "row",
        }}
      >
        <Box sx={{ width: { xs: "100%", md: "30%" } }}>
          {/* Left column */}
          <ChangePaymentMethodSelect
            account={account}
            consent={consent}
            setConsent={setConsent}
            setErrorMessage={setErrorMessage}
            setCurrentSortCode={setCurrentSortCode}
            setCurrentAccountNumber={setCurrentAccountNumber}
            selectedMethod={selectedMethod}
            setSelectedMethod={setSelectedMethod}
            savePaymentMethod={savePaymentMethod}
          />
          {/* End of left column */}
        </Box>
        <Box sx={{ width: { xs: "100%", md: "70%" } }}>
          {/* Right column */}
          {selectedMethod === 11 ?
            <ArrangementDirectDebitForm
              consent={consent}
              setConsent={setConsent}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              currentSortCode={currentSortCode}
              setCurrentSortCode={setCurrentSortCode}
              currentAccountNumber={currentAccountNumber}
              setCurrentAccountNumber={setCurrentAccountNumber}
            /> : 
            <ArrangementCardForm consent={consent} setConsent={setConsent} />}
          {/* End of right column */}
        </Box>
      </Stack>
    </>
  )
}

export default ChangePaymentMethod;