import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useClient } from "../../context/ClientProvider";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LinkIcon from "@mui/icons-material/Link";
import ScrollToTopButton from "../ScrollToTopButton";
const Footer = () => {
  const { useGetConfig } = useClient();
  const { clientConfig } = useGetConfig();

  return (
    <>
      <ScrollToTopButton /> 
    <Box
      component='footer'
      sx={{
        backgroundColor: "secondary.light",
      }}
    >

      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        paddingY={{ xs: 5, md: 10 }}
        paddingX={{ xs: 2, md: 10 }}
      >
        {clientConfig?.layout &&
          clientConfig?.layout.footer.map((item, idx) => {
            return (
              <Grid
                id={`footer-${item.title
                  .replace(" ", "-")
                  .toLowerCase()}-column`}
                key={idx}
                item
                xs={6}
                md={3}
              >
                <Typography
                  variant='h6'
                  component='div'
                  marginRight={3.75}
                  fontWeight={600}
                >
                  {idx === 0 ? (
                    <ListAltIcon sx={{ marginRight: "5px" }} />
                  ) : (
                    <LinkIcon
                      sx={{ marginRight: "5px", marginBottom: "5px" }}
                    />
                  )}
                  {item.title}
                </Typography>
                <List dense>
                  {item.links.map((link, idx) => (
                    <ListItem key={idx}>
                      <ListItemButton
                        component='a'
                        href={link.url}
                        target='_blank'
                        sx={{ height: "28px" }}
                      >
                        <ListItemText primary={link.label} secondary={null} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            );
          })}
      </Grid>
      </Box>
    </>
  );
};

export default Footer;
