import { useEffect, useLayoutEffect } from "react";
import { Box, Container } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { useNavigate } from "react-router-dom";

import ArrangementStepper from "../arrangement/ArrangementStepper";

import { useNav } from "../../context/NavProvider";
import { useAccount } from "../../context/AccountProvider";
import { useArrangement } from "../../context/ArrangementProvider";
import { useSfs } from "../../context/SfsProvider";

/**
 * Arrangement page component
 * @returns
 */
function ArrangementPage() {

  const { setShowFooter } = useNav();
  setShowFooter(false);

  const { useGetAccount } = useAccount();
  const {
    useGetSfs,
    loadSfsData,
    sfsLoaded,
    disposableIncome,
    monthsSinceReview,
  } = useSfs();
  const { useGetArrangement, loadPlanData, planLoaded, lastSavedPlan } =
    useArrangement();

  const { account, isAccountLoading } = useGetAccount();
  const { sfs, isSfsLoading } = useGetSfs();
  const { arrangement, isArrangementLoading } = useGetArrangement();

  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to SFS page if low DI or out of date review
    if (
      !isSfsLoading && sfsLoaded && !isArrangementLoading && planLoaded &&
      ((monthsSinceReview === null || monthsSinceReview >= 6) || disposableIncome < 1)
    ) {
      navigate("/sfs");
    }
  }, [disposableIncome]);

  useLayoutEffect(() => {
    // Redirect to home and do not allow plan changes in payment window
    if (lastSavedPlan && lastSavedPlan?.inPaymentWindow === true) {
      navigate("/");
    }
  }, [lastSavedPlan]);

  useEffect(() => {
    // Redirect to home and do not allow plan changes in payment window
    if (lastSavedPlan && lastSavedPlan?.inPaymentWindow === true) {
      navigate("/");
    }
  }, [lastSavedPlan]);

  if (isAccountLoading || isSfsLoading || isArrangementLoading) {
    return <LinearProgress />;
  }

  // Only load plan when arrangement & DI have loaded to support calculations
  if (!planLoaded && disposableIncome) {
    loadPlanData(arrangement);
  }

  if (!sfsLoaded) {
    loadSfsData(sfs);
  }

  return (
    account &&
    planLoaded &&
    sfsLoaded &&
    disposableIncome >= 1 && (
      <>
        <Container sx={{ mt: 4, mb: 7, paddingBottom: "350px" }}>
          <Box>
            <ArrangementStepper />
          </Box>
        </Container>
        {setShowFooter(true)}
      </>
    )
  );
}

export default ArrangementPage;
