import { createContext, useContext, useState, useEffect } from "react";
import dayjs from "dayjs";

import environment from "../env";
import { useAuth } from "./AuthProvider";
import useSWRImmutable from "swr/immutable";

import { useAccount } from "./AccountProvider";
import { useSfs } from "./SfsProvider";

const ArrangementContext = createContext();

/**
 * Arrangement custom hook
 * */
export const useArrangement = () => useContext(ArrangementContext);

const ArrangementProvider = ({ children }) => {
  const { accessToken } = useAuth();

  const { useGetAccount } = useAccount();
  const { account } = useGetAccount();
  const { disposableIncome } = useSfs();

  const [ hasArrangement, setHasArrangement ] = useState(null);
  const [ plan, setPlan ] = useState(null);
  const [ lastSavedPlan, setLastSavedPlan ] = useState(null);
  const [ planMode, setPlanMode ] = useState(null);
  const [ planLoaded, setPlanLoaded ] = useState(false);

  const apiKey = environment.CUSTOMER_PORTAL_API_KEY;
  const apiUrl = environment.CUSTOMER_PORTAL_API;

  const MAX_INSTALMENTS = 250;
  const PAYMENT_BUFFER_DAYS = 10;

  const defaultOptions = {
    method: "GET",
    headers: {
      "x-api-key": apiKey,
      Authorization: `Bearer ${accessToken}`,
    },
  };

  /**
   * Post arrangement callback
   * @param {*} url
   * @param {*} arg
   * @returns
   */
  async function postArrangement(url, arrangement) {
    let requestOptions = {
      ...defaultOptions,
      method: "POST",
      body: JSON.stringify(arrangement),
    };
    console.info("post arrangement request: ", { url, requestOptions });
    await fetch(`${apiUrl}/arrangement`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log("post arrangement response: ", data);
      })
      .catch((error) => {
        console.log(`Error in post arrangement: ${error}`);
      });
  }

  /**
   * Post propose arrangement callback
   * @param {*} url
   * @param {*} arg
   * @returns
   */
  async function postProposeArrangement(url, { arg }) {
    let requestOptions = {
      ...defaultOptions,
      method: "POST",
      body: JSON.stringify(arg),
    };
    console.info("post propose arrangement request: ", { url, requestOptions });
    return fetch(`${apiUrl}${url}`, requestOptions).then((res) => res.json());
  }

  /**
   * Get arrangement SWR hook
   * @returns
   */
  function useGetArrangement() {
    const url = accessToken && `${apiUrl}/arrangement`;
    console.info("Get arrangement request", { defaultOptions, url });
    const { data, error, isLoading } = useSWRImmutable([url, defaultOptions]);
    console.info("Get arrangement response", { data, error, isLoading });
    return {
      arrangement: data,
      error,
      isArrangementLoading: isLoading,
    };
  }

  const paymentMethods = {
    "CASH PAYMENT": 1,
    "CHECK": 2,
    "MONEY ORDER": 3,
    "WESTERN UNION": 4,
    "DEBIT CARD PAYMENT": 5,
    "PAPER DRAFT": 6,
    "ACH DEBIT": 7,
    "POST-DATED CHECK": 8,
    "MERCHANDISE RETURN": 9,
    "RESERVED": 10,
    "UK DIRECT DEBIT": 11,
    "STANDING ORDER": 12,
    "DEBT MANAGEMENT": 13,
    "DIRECT TO BANK": 14,
    "CHEQUE PAYMENT": 15,
    "DIRECT TO CLIENT": 16,
    "PAYPOINT (BARCODE)": 17,
    "GOOGLE PAY": 18,
    "APPLE PAY": 19,
    "CHAPS": 20,
    "CASH": 21,
    "DEBIT CARD": 22,
    "UNSPECIFIED": 23,
    "OTHER": 24,
    "DEBIT DEBIT MANDATE": 25,
    "CLIENT CONTROLLED DD": 26,
    "SEPA": 28,
    "PAYPAL": 29,
    "DEBT ARR SCHEME": 30,
    "POSTAL ORDER": 31,
    "PAYMENT BOOK": 32,
  };

  const paymentFrequencies = {
    monthly: "Monthly",
    weekly: "Weekly",
    biweekly: "Fortnightly",
    every28days: "4 Weekly",
  };

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  /**
   * Calculate start date on load and when frequency changes
   * @returns
   */
  const calculateStartDate = (paymentFrequency, monthlyDay, weeklyDay) => {

    // Start date cannot be before buffered date so start there
    let newStartDate = dayjs().add(PAYMENT_BUFFER_DAYS, "day");

    if (paymentFrequency, monthlyDay, weeklyDay) {
      // Now depending on frequency add any required further days
      if (paymentFrequency === "monthly") {
        // Move to next available selected day eg Day 1
        while (newStartDate.date() !== Number(monthlyDay)) {
          newStartDate = newStartDate.add("1", "day");
        }
      } else {
        // Move to next available day of week eg Tuesday
        while (daysOfWeek[newStartDate.day()] !== weeklyDay) {
          newStartDate = newStartDate.add("1", "day");
        }
      }
    }

    return newStartDate;
  };

  /**
   * Calculate final payment amount
   * @returns
   */
  const getFinalPaymentAmount = (balance, paymentAmount, numInstalments) => {

    let finalPaymentAmount = null;

    // Calculate if all arguments have value
    if (balance && paymentAmount && numInstalments) {
      const totalRepaymentAmount = paymentAmount * numInstalments;
      if (totalRepaymentAmount > balance) {
        const overpaymentAmount = totalRepaymentAmount - balance;
        finalPaymentAmount = paymentAmount - overpaymentAmount; 
      } else {
        finalPaymentAmount = paymentAmount;
      }
    }

    return finalPaymentAmount;
  }

  /**
   * Co-ordinate load of external arrangement data into state
   * @returns
   */
  const loadPlanData = (arrangement) => {
    console.log("Load Arrangement Data: ", arrangement);

    // Handle if missing arrangement
    let hasArrangement = true;
    if (arrangement === null || arrangement === "{}" || Object.keys(arrangement).length === 0) {
      hasArrangement = false;
    };

    const currentDate = dayjs();

    // Extract payment frequency information
    const paymentFrequency = hasArrangement ? arrangement?.paymentFrequency : "monthly";
    const paymentFrequencyArgs = hasArrangement && arrangement?.paymentFrequencyArgs
        ? arrangement?.paymentFrequencyArgs?.split(",")[0] : "1";
    const paymentMonthlyDay = paymentFrequency === "monthly" ? paymentFrequencyArgs : "1";
    const paymentWeeklyDay = paymentFrequency !== "monthly" ? paymentFrequencyArgs : "Monday";

    // Extract values needed where calculations required
    const paymentAmount = hasArrangement ? arrangement?.instalmentAmount : 
      disposableIncome <= account?.currentBalance ? disposableIncome : account?.currentBalance;
    let numInstalments;
    let totalPayable;
    if (hasArrangement) {
      numInstalments = arrangement?.numberOfInstalments;
      totalPayable = arrangement?.totalPayable;
    } else {
      const defaultNumInstalments = Number(Math.ceil(account?.currentBalance/disposableIncome));
      if (defaultNumInstalments <= MAX_INSTALMENTS) {
        numInstalments = defaultNumInstalments;
        totalPayable = account?.currentBalance;
      } else {
        numInstalments = MAX_INSTALMENTS;
        totalPayable = MAX_INSTALMENTS * paymentAmount;
      }
    }

    // Calculate if plan is in direct debit payment window
    const paymentMethod = hasArrangement ? paymentMethods[arrangement?.paymentMethod] :
      paymentMethods["UK DIRECT DEBIT"];
    const nextPaymentDate = hasArrangement ? arrangement?.nextPaymentDate : 
      calculateStartDate(paymentFrequency, paymentMonthlyDay, paymentWeeklyDay);
    const diff = Math.ceil(dayjs(nextPaymentDate).diff(currentDate, "day", true));
    const inPaymentWindow = hasArrangement ? 
      ((paymentMethod === paymentMethods["UK DIRECT DEBIT"]) && (diff <= PAYMENT_BUFFER_DAYS)) ? true : false
      : false;

    // Plan details
    const planData = {
      dateCreated: hasArrangement ? dayjs(arrangement?.dateCreated) : currentDate,
      instalmentAmount: paymentAmount,
      nextPaymentAmount: hasArrangement ? arrangement?.nextPaymentAmount : null,
      nextPaymentDate: nextPaymentDate,
      inPaymentWindow: inPaymentWindow,
      numberOfInstalments: numInstalments,
      paymentsLeft: hasArrangement ? arrangement?.paymentsLeft : numInstalments,
      paidToDate: hasArrangement ? arrangement?.paidToDate : 0,
      paymentFrequency: paymentFrequency,
      paymentMethod: paymentMethod,
      totalPayable: totalPayable,      
      totalRemaining: hasArrangement ? arrangement?.totalRemaining : totalPayable,
      sortCode: hasArrangement ? arrangement?.sortCode : null,
      accountNumber: hasArrangement ? arrangement?.accountNumber : null,
      walletId: hasArrangement ? arrangement?.walletId : null,
      isPromiseToPay: hasArrangement ? arrangement?.isPromiseToPay : true,
      endDate: getPlanEndDate(
        hasArrangement ? arrangement?.dateCreated : currentDate,
        hasArrangement ? arrangement?.numberOfInstalments : numInstalments,
        hasArrangement ? arrangement?.paymentFrequency : paymentFrequency,
      ),
      weeklyDay: paymentWeeklyDay,
      monthlyPickOrdinal: null,
      monthlyPickDay: null,
      monthlyDay: paymentMonthlyDay,
      finalPaymentAmount: getFinalPaymentAmount(account?.currentBalance, paymentAmount, numInstalments)
    };
    console.log("PLAN DATA: ", planData);
    setPlan(planData);
    setLastSavedPlan(planData);

    // General plan settings
    setPlanMode(hasArrangement ? "amend" : "create");
    setHasArrangement(hasArrangement ? true : false);
    setPlanLoaded(true);
  };

  function getPlanEndDate(startDate, numPayments, frequency) {
    const endDate = dayjs(startDate).add(
      numPayments,
      frequencyConversion[frequency]
    );
    return endDate;
  }

  useEffect(() => {
    if (account && plan && disposableIncome && disposableIncome >= 1) {
      // On DI change, update the default plan used by create arrangement feature
      if (hasArrangement === false) {
        const currentDate = dayjs();
        const paymentAmount = disposableIncome <= account?.currentBalance ? disposableIncome : account?.currentBalance;
        const defaultNumInstalments = Number(Math.ceil(account?.currentBalance/disposableIncome));
        let numInstalments;
        let totalPayable;
        if (defaultNumInstalments <= MAX_INSTALMENTS) {
          numInstalments = defaultNumInstalments;
          totalPayable = account?.currentBalance;
        } else {
          numInstalments = MAX_INSTALMENTS;
          totalPayable = MAX_INSTALMENTS * paymentAmount;
        }
        setPlan({
          ...plan,
          instalmentAmount: paymentAmount,
          numberOfInstalments: numInstalments,
          totalPayable: totalPayable,
          totalRemaining: totalPayable,
          endDate: getPlanEndDate(currentDate, numInstalments, plan?.paymentFrequency),
          finalPaymentAmount: getFinalPaymentAmount(account?.currentBalance, paymentAmount, numInstalments)
        });
      }
    }
  }, [disposableIncome]);

  /**
   * Co-ordinate dump of internal arrangement data into POST body
   * @returns
   */
  const savePlanData = (plan) => {
    console.log("save plan data: ", plan);

    const arrangementObj = {
      startDate: plan?.dateCreated.format("YYYY-MM-DD"),
      paymentAmount: plan?.instalmentAmount,
      totalAmount: plan?.totalPayable,
      frequency: plan?.paymentFrequency,
      paymentMethod: plan?.paymentMethod,
      walletId: plan?.walletId,
      bankAccountNumber: plan?.accountNumber,
      sortCode: plan?.sortCode,
      SIF: false,
      weeklyDay: plan?.paymentFrequency !== "monthly" ? plan?.weeklyDay : null,
      monthlyPickOrdinal: plan?.monthlyPickOrdinal,
      monthlyPickDay: plan?.monthlyPickDay,
      monthlyDay:
        plan?.paymentFrequency === "monthly" ? plan?.monthlyDay : null,
      isPromiseToPay: plan?.isPromiseToPay,
    };

    console.log("New Arrangement POST Object: ", arrangementObj);

    if (accessToken) {
      postArrangement(`${apiUrl}/arrangement`, arrangementObj);
    }

    // Make plan the new last saved plan for dashboard widget etc
    setLastSavedPlan(plan);
  };

  const frequencyConversion = {
    monthly: "months",
    weekly: "weeks",
    biweekly: "weeks",
    every28days: "weeks",
  };

  /**
   * Update arrangement end date by frequency
   * @param {*} frequency
   */
  const updatePlanEndDateByFrequency = (frequency) => {
    switch (frequency) {
      case "monthly":
      case "weekly":
        setPlan({
          ...plan,
          paymentFrequency: frequency,
          endDate: plan?.dateCreated.add(
            plan?.numberOfInstalments,
            frequencyConversion[frequency]
          ),
        });
        break;
      case "biweekly":
        setPlan({
          ...plan,
          paymentFrequency: frequency,
          endDate: plan?.dateCreated.add(
            Math.ceil(plan?.numberOfInstalments * 2),
            "weeks"
          ),
        });
        break;
      case "every28days":
        setPlan({
          ...plan,
          paymentFrequency: frequency,
          endDate: plan?.dateCreated.add(
            Math.ceil(plan?.numberOfInstalments * 4),
            "weeks"
          ),
        });
        break;
      default:
        break;
    }
  };

  const updatePlanEndDateByAmount = (amount) => {

    // If this amount is over DI do not allow it
    if (amount > disposableIncome) {
      return;
    }

    // How many instalments would clear the balance using the new amount?
    const totalInstalments = Number(Math.ceil(account?.currentBalance / amount));

    let planNumInstalments = null;
    let finalPaymentAmount = null;
    let planTotalAmount = null;

    if (totalInstalments <= MAX_INSTALMENTS) {
      // This amount can clear the balance without exceeding max allowed instalments
      planNumInstalments = totalInstalments;
      finalPaymentAmount = getFinalPaymentAmount(account?.currentBalance, amount, planNumInstalments);
      planTotalAmount = account?.currentBalance;
    } else {
      // This amount cannot clear the balance so cap at max instalments
      planNumInstalments = MAX_INSTALMENTS;
      finalPaymentAmount = amount;
      planTotalAmount = amount * planNumInstalments;
    }

    // Recalculate end date using new instalment number
    switch (plan?.paymentFrequency) {
      case "monthly":
      case "weekly":
        setPlan({
          ...plan,
          instalmentAmount: amount,
          numberOfInstalments: planNumInstalments,
          paymentsLeft: planNumInstalments,
          totalPayable: planTotalAmount,
          totalRemaining: planTotalAmount,
          paidToDate: 0,
          finalPaymentAmount: finalPaymentAmount,
          endDate: plan?.dateCreated.add(
            planNumInstalments,
            frequencyConversion[plan?.paymentFrequency]
          ),
        });
        break;
      case "biweekly":
        setPlan({
          ...plan,
          instalmentAmount: amount,
          numberOfInstalments: planNumInstalments,
          paymentsLeft: planNumInstalments,
          totalPayable: planTotalAmount,
          totalRemaining: planTotalAmount,
          paidToDate: 0,
          finalPaymentAmount: finalPaymentAmount,
          endDate: plan?.dateCreated.add(
            Math.ceil(planNumInstalments * 2),
            "weeks"
          ),
        });
        break;
      case "every28days":
        setPlan({
          ...plan,
          instalmentAmount: amount,
          numberOfInstalments: planNumInstalments,
          paymentsLeft: planNumInstalments,
          totalPayable: planTotalAmount,
          totalRemaining: planTotalAmount,
          paidToDate: 0,
          finalPaymentAmount: finalPaymentAmount,
          endDate: plan?.dateCreated.add(
            Math.ceil(planNumInstalments * 4),
            "weeks"
          ),
        });
        break;
      default:
        break;
    }
  };

  const updatePlanEndDateByPlanStartDate = (startDate) => {
    // Recalculate end date using new start date
    switch (plan?.paymentFrequency) {
      case "monthly":
      case "weekly":
        setPlan({
          ...plan,
          dateCreated: startDate,
          endDate: startDate.add(
            plan?.numberOfInstalments,
            frequencyConversion[plan?.paymentFrequency]
          ),
        });
        break;
      case "biweekly":
        setPlan({
          ...plan,
          dateCreated: startDate,
          endDate: startDate.add(
            Math.ceil(plan?.numberOfInstalments * 2),
            "weeks"
          ),
        });
        break;
      case "every28days":
        setPlan({
          ...plan,
          dateCreated: startDate,
          endDate: startDate.add(
            Math.ceil(plan?.numberOfInstalments * 4),
            "weeks"
          ),
        });
        break;
      default:
        break;
    }
  };

  const contextValue = {
    useGetArrangement,
    postArrangement,
    postProposeArrangement,
    loadPlanData,
    savePlanData,
    planLoaded,
    setPlanLoaded,
    planMode,
    setPlanMode,
    plan,
    setPlan,
    lastSavedPlan,
    setLastSavedPlan,
    updatePlanEndDateByFrequency,
    updatePlanEndDateByAmount,
    updatePlanEndDateByPlanStartDate,
    paymentMethods,
    paymentFrequencies,
    hasArrangement,
    setHasArrangement,
    getFinalPaymentAmount,
    calculateStartDate,
    getPlanEndDate,
    daysOfWeek,
  };

  return (
    <ArrangementContext.Provider value={contextValue}>
      {children}
    </ArrangementContext.Provider>
  );
};

export default ArrangementProvider;
