import { useState, useEffect } from "react";
import { Box, Stack, Typography, Card, CardContent } from "@mui/material";
import ScrollToTop from "../nav/ScrollToTop";
import PaymentQuestions from "./PaymentQuestions";
import PaymentInformation from "./PaymentInformation";

/**
 * Payment amount
 * @param {*} param0
 * @returns
 */
function PaymentAmount({
  handleNext,
  balance,
  paymentAmount,
  updatePaymentAmount,
}) {
  const [hideRightColumn, setHideRightColumn] = useState(false);
  const [showSfsHelp, setShowSfsHelp] = useState(false);

  useEffect(() => {
    setShowSfsHelp(hideRightColumn);
  }, [hideRightColumn]);

  return (
    <>
      <ScrollToTop />
      <Stack
        sx={{
          mt: 2,
        }}
        spacing={2}
        direction={{
          xs: "column",
          md: "row",
        }}
      >
        <Box sx={{ width: hideRightColumn ? "100%" : "90%" }}>
          {/* Card with heading and text */}
          {showSfsHelp ? (
            <Card sx={{ mb: 2 }}>
              <CardContent>
                <Typography
                  variant='h6'
                  fontWeight={"bold"}
                  paddingBottom={1}
                  textAlign='left'
                >
                  Before you confirm, we're here to help you
                </Typography>
                <Typography textAlign='left'>
                  We believe you've answered some questions that signal you
                  might need some assistance. We'll never ask you to repay more
                  than you can afford - first and foremost, we're simply here to
                  support you with this debt, and the improvement of your
                  financial health.
                </Typography>
                <Typography textAlign='left'>
                  you address your current balance, learn more about budgeting,
                  understand your credit file, and more - all of which are
                  things you maybe weren’t already aware of.
                </Typography>
                <Typography textAlign='left'>
                  It can be tough to talk about money, we understand that - so,
                  for independent, free advice - you may want to contact the
                  following organisations: If you feel that you're struggling
                  with your finances, speaking with someone impartial could
                  provide you with options to help
                </Typography>
              </CardContent>
            </Card>
          ) : (
            <Typography
              fontWeight={"bold"}
              paddingBottom={1}
              textAlign='left'
            >
              Make a payment
            </Typography>
          )}

          <PaymentQuestions
            balance={balance}
            handleNext={handleNext}
            paymentAmount={paymentAmount}
            updatePaymentAmount={updatePaymentAmount}
            setHideRightColumn={setHideRightColumn}
            showSfsHelp={showSfsHelp}
            setShowSfsHelp={setShowSfsHelp}
          />
        </Box>

        {/* Right hand column */}
        {!hideRightColumn && (
          <Box sx={{ width: { xs: "100%", md: "40%" } }}>
            <Typography fontWeight={"bold"} textAlign='left' paddingBottom={1}>
              Payment information
            </Typography>
            <PaymentInformation />
          </Box>
        )}
        {/* End of right hand column */}
      </Stack>
    </>
  );
}

export default PaymentAmount;
